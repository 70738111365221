body {
	background-color: #eee;
}

.list-item>* {
	border: 1px solid #f8f9fa;
}

.list-item>.preview {
	border: 1px solid #a4a5a6;
}

.list-item>.selected {
	border: 1px solid #007bff;
}

/* Background image */

.trip-feature-image {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-attachment: fixed;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	transition: opacity 1s ease, filter 1s ease;

	opacity: 0;
	filter: blur(15px);

	z-index: -10;
}

.trip-feature-image.active {
	opacity: 0.5;

	z-index: -9;
}

.trip-feature-image.active.focus-1 {
	opacity: 0.9;
	filter: blur(2px);
}

.trip-feature-image.active.focus-2 {
	opacity: 0.8;
	filter: blur(4px);
}

.trip-feature-image.active.focus-3 {
	opacity: 0.7;
	filter: blur(7px);
}

.trip-feature-image.active.focus-4 {
	opacity: 0.6;
	filter: blur(10px);
}

/* Tab buttons */

.booking-form nav.nav>a.nav-link {
	opacity: 0.5;
	color: #444;
	background: #ddd;
	backdrop-filter: blur(30px);
}

.booking-form nav.nav>a.nav-link.disabled {
	opacity: 0.1;
	color: #444;
	background: #fff;
}

.booking-form nav.nav>a.nav-link.active {
	opacity: 1;
	color: #444;
	background: #fff;
}
